<template>
  <div class="page-sidebar">
    <div class="sidebar-logo">
      <img src="@/assets/logo_sidebar.png">
    </div>
    <ul class="sidebar-ul">
      <template v-for="(menu,index) in side_menu">
      <li class="sidebar-li" :key="index">
        <div class="sidebar-li-box" :class="{'selected-li':side_selected==menu.permission}" @click="changeSide(menu.permission)">
          <img v-if="side_selected == menu.permission" :src="getIcon(menu.permission,true)">
          <img v-else :src="getIcon(menu.permission,false)">
          <span :class="{'selected-span':side_selected==menu.permission}">{{menu.name}}</span>
          <img class="more" v-if="menu.adminPermissions != null && side_selected!=menu.permission" src="@/assets/sidebar_icon_more.png">
        </div>
        <ul class="sidebar-child-ul" v-if="side_selected_box == menu.permission && menu.adminPermissions != null">
          <template v-for="(child,index2) in menu.adminPermissions">
          <li class="sidebar-child-li" :key="index2" @click="changeSideChild(child.permission)">
            <span :class="{'selected-span':side_selected_child==child.permission}">{{child.name}}</span>
          </li>
          </template>
        </ul>
      </li>
      </template>
    </ul>
  </div>
</template>

<script>
import {getCurrent} from "../request/api";

export default {
  name: "sidebarEl",
  props:{

  },
  data() {
    return {
      side_selected:'home',
      side_selected_box:'home',
      side_selected_child:'home',
      side_menu:[{
        "id":0,
        "name":"首页",
        "permission":"home"
      }]
    }
  },
  computed: {},
  created() {
    this.getCurrent();
    this.initSide();
  },
  mounted() {
  },
  methods: {
    changeSide(tag){
      if(this.side_selected_box == tag){
        this.side_selected = tag;
        this.side_selected_box = "";
        this.side_selected_child = "";
      }else{
        this.side_selected = tag;
        this.side_selected_box = tag;
        this.side_selected_child = "";
        if(tag == 'home'){
          this.$router.push({name:'home'}, ()=>{}, ()=>{})
        }
      }
    },
    changeSideChild(tag){
      console.log(tag);
      this.side_selected_child = tag
      this.$router.push({name:tag}, ()=>{}, ()=>{})
    },
    initSide(){
      const {path} = this.$route;
      let arr = path.split('/')
      let tag = arr[1];
      if(tag != 'home'){
        let childTag = arr[2];
        this.side_selected = tag;
        this.side_selected_box = tag;
        this.side_selected_child = tag+"_"+childTag;
      }else{
        this.side_selected = tag;
        this.side_selected_box = "";
        this.side_selected_child = "";
      }
    },
    getCurrent(){
      const requestData = {}
      getCurrent(requestData).then((res)=>{
        this.side_menu = res.data.adminPermissions;
        this.side_menu.unshift({
          "id":0,
          "name":"首页",
          "permission":"home"
        })
        this.$store.commit('infoModule/updateUserInfo',res.data);
      })
    },
    getIcon(tag,selected){
      if(selected){
        return require("@/assets/sidebar_icon_"+tag+"_selected.png");
      }else{
        return require("@/assets/sidebar_icon_"+tag+"_unselect.png");
      }
    }
  },
  watch: {
    $route() {
      this.initSide();
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/css/_var.scss";
.page-sidebar{
  width: 333px;
  //height: 100vh;
  background: #fff;
  .sidebar-logo{
    margin-left: 52px;
    & > img{
      margin-top: 42px;
      width: 250px;
      height: 95px;
    }
  }
  .sidebar-ul{
    width: 250px;
    margin-left: 52px;
    .sidebar-li{
      width: 250px;
      font-size: 18px;
      margin-bottom: 10px;
      font-weight: 400;
      .sidebar-li-box{
        height: 44px;
        line-height: 44px;
        cursor: pointer;
        & > span{
          height: 44px;
          line-height: 44px;
          margin-left: 14px;
        }
        & > img{
          height: 44px;
          width: 44px;
          vertical-align:middle;
          margin-left: 16px;
        }
        .more{
          height: 44px;
          width: 44px;
          float: right;

        }
      }
      .sidebar-child-ul{
        width: 200px;
        margin-left: 62px;
        margin-top: 15px;
        .sidebar-child-li{
          height: 44px;
          line-height: 44px;
          font-weight: 400;
          font-size: 18px;
          cursor: pointer;
          & > span{
            height: 44px;
            line-height: 44px;
            margin-left: 14px;
          }
        }
      }
    }
    .selected-li{
      border-radius: 30px;
      background: #ddd1b4;
    }
    .selected-span{
      color: #dc2525;
    }
  }
}
</style>
