<template>
  <div class="page-footer">
    Copyright By brandesign.org.cn 2023
  </div>
</template>

<script>
export default {
  name: "footerEl",
  props:{

  },
  data() {
    return {

    }
  },
  computed: {},
  created() {
  },
  mounted() {
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>
@import "~@/css/_var.scss";
.page-footer{
  //width: calc(100% - 333px);
  width: 100%;
  height: 40px;
  line-height: 40px;
  position: relative;
  padding-right: 10px;
  background: #3c3c3c;
  color: #fff;
  font-size: 14px;
  text-align: right;
}
</style>
