<template>
  <div class="page-header">
    <div class="header-user" @click="showWindow()">
      <img src="@/assets/header_user_avatar_default.png">
      <span>{{username}}</span>
    </div>
    <div v-if="window_show" class="header-user-window" @click="loginOut()">
      <span>登出</span>
    </div>
  </div>
</template>

<script>
import store from '@/store/index';
export default {
  name: "headerEl",
  data() {
    return {
      username:"用户名",
      window_show:false
    }
  },
  computed: {

  },
  created() {
    const userInfo = store.getters["infoModule/getUserInfoObj"];
    this.username = userInfo.username;
  },
  mounted() {

  },
  methods: {
    showWindow(){
      if(this.window_show){
        this.window_show = false;
      }else{
        this.window_show = true;
      }
    },
    loginOut(){
      this.$store.commit('infoModule/updateToken',"");
      this.$router.push({name:'login'})
    }
  },
  watch: {

  }
}
</script>

<style lang="scss" scoped>
.page-header{
  height: 80px;
  width: 100%;
  position: relative;
  .header-user{
    height: 80px;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    & > img{
      width: 80px;
      height: 80px;
      float: right;
    }
    & > span{
      height: 80px;
      line-height: 80px;
      float: right;
      margin-right: 30px;
      font-size: 18px;
    }
  }
  .header-user-window{
    height: 40px;
    width: 80px;
    background: #fff;
    position: absolute;
    top: 60px;
    right: 90px;
    font-size: 18px;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
    border-radius: 15px;
  }
}
</style>
